import { Box, Button, IconButton, Menu, MenuItem, Typography } from '@octanner/prism-core';
import { MenuDots } from '@octanner/prism-icons';
import React from 'react';

const EditButton = () => <Button sx={{ minWidth: '0px', height: 'fit-content' }} variant="text" onClick={() => console.log('edit')}>
  Edit
</Button>

const MenuButton = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: React.BaseSyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuDots />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Edit Action</MenuItem>
        <MenuItem sx={{ color: 'red' }} onClick={handleClose}>
          Delete Action
        </MenuItem>
      </Menu>
    </div>
  );
}

const TimelineItemFooter = () => {
  const [collapsed, setCollapsed] = React.useState(true);
  const pointLevels = [
    {
      year: '1 year',
      range: '10-100',
    },
    {
      year: '3 year',
      range: '10-100',
    },
    {
      year: '5 year',
      range: '10-100',
    },
    {
      year: '10 year',
      range: '10-100',
    },
    {
      year: '15 year',
      range: '10-100',
    },
    {
      year: '20 year',
      range: '10-100',
    },
    {
      year: '25 year',
      range: '10-100',
    },
    {
      year: '30 year',
      range: '10-100',
    },
    {
      year: '35 year',
      range: '10-100',
    },
    {
      year: '40 year',
      range: '10-100',
    },
    {
      year: '45 year',
      range: '10-100',
    },
    {
      year: '50 year',
      range: '10-100',
    },
  ];

  return (
    <Box sx={{ borderBottom: `1px solid '#E1E1E1'`, paddingBottom: '16px' }}>
      <Button
        variant="text"
        size="small"
        onClick={() => setCollapsed((prevState) => !prevState)}
        sx={{ padding: 'none', mb: '8px' }}
      >
        {collapsed ? 'Show point levels' : 'Hide Point Levels'}
      </Button>
      {!collapsed && (
        <Box>
          <>
            <Typography>Point levels:</Typography>
          </>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {pointLevels.map((level) => (
              <Box sx={{ display: 'flex', width: 'fit-content' }}>
                <Typography fontWeight="bold">
                  {level.year}:&nbsp;
                </Typography>
                <Typography>{level.range},&nbsp; </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export const simpleEvents = [{ title: '1 year anniversary' }, { title: '3 year anniversary' }, { title: '5 year anniversary' }]
export const simpleOutlinedEvents = [{ title: '1 year anniversary', dotVariant: 'outlined' }, { title: '3 year anniversary', dotVariant: 'outlined' }, { title: '5 year anniversary', dotVariant: 'outlined' }]
export const events = [
  {
    timeFrame: 60,
    timeFrameUnit: 'days',
    title: 'Celebration Start',
    button: EditButton,
    tags: [{ title: 'System' }],
  },
  {
    timeFrame: 30,
    timeFrameUnit: 'days',
    title: 'Celebrant personal note invite',
    button: MenuButton,
    tags: [
      { title: 'Notification' },
      {
        title: '+7 Days',
        infoText:
          '7 day delay added for orders that are shipped internationally',
      },
      {
        title: '3 Reminders',
        infoText:
          'Reminder emails are sent every 7 days following the initial notification.',
      },
    ],
  },
  {
    timeFrame: 0,
    timeFrameUnit: 'days',
    title: 'User selected gift',
    description: 'User selected gift for the celebration',
    button: MenuButton,
    timelineItemFooter: TimelineItemFooter,
    tags: [
      { title: 'Anniversary gift' },
      { title: 'Notification' },
      {
        title: '3 Reminders',
        infoText:
          'Reminder emails are sent every 7 days following the initial notification.',
      },
    ],
  },
];

export default events